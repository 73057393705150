.vega-embed {
  height: 100vh;
  width: 100vw;
  display: flex !important;
  flex-direction: column-reverse;
}
.vega-bindings{
  height: 20px;
  display: flex;
  flex-direction: row;
}
.gridBox{
  height: 500px !important;
}
.pacText{
  color: black;
}
.test{
  height: 100vh;
}
.svgIcon{
  top: .125em;
  position: relative;
}
.legendItem{
  padding-right: 15px;
  white-space: nowrap;
}
.raceSelect{
  align-items: center;
  display: flex;
  cursor: pointer;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.raceSelectDropdown{
  font-size: inherit;
  display: flex;
  flex-direction: column;
}
.raceSelectDropownButton {
  cursor: pointer;
  padding: 5px;
}
.raceSelectDropownButton:hover {
  background-color: #F2F2F2;
}

.testarosta{
  width: 100% !important;
  display:block;
  overflow: auto;
}
.dashFooter{
  width: 100% !important;
}
#root > div > div > div.StyledBox-sc-13pk1d4-0.khTSjy > table > thead > tr > th > button > div > span {
  color:  #919eab !important;
}

.testarosta tr td{
  color: rgb(0, 0, 0) !important;
}

.pacHeader{
  color:  #919eab !important;
}

.dashTitle{
  color: black !important;
}
.testarosta tr th:nth-child(1){ 
    width: 20px;
}
.testarosta tr td:nth-child(1){
  width: 20px;
}
.bd-color{border-radius:2px;box-shadow:0 2px 3px 0 rgba(0,0,0,.1),inset 0 0 0 1px rgba(0,0,0,.1);display:inline-block;height:18px;margin-right:8px;width:18px}
.testarosta tr th:nth-child(3){
    width:80px;
}
.noLeftPad {
  padding-left: 0px !important;
}
.testarosta tr td:nth-child(3){
    width:80px;
}
.testarosta tr th:nth-child(4){
    width:40px;
}
.testarosta tr td:nth-child(4){
    width:40px;
}
.testarosta > thead > tr > th > button > div > svg {
  display:none;
}
.testarosta tr th:nth-child(5){
    width:200px;
}

.testarosta > thead > tr > th:nth-child(5) > button{
  display: contents !important;
}
.testarosta tr td:nth-child(5){
    width:200px;
}
.testarosta tr th:nth-child(6){
    width:8%;
}
.testarosta tr td:nth-child(6){
    width:8%;
}
.dashFooter tr td:nth-child(1){
  width: 30%;
}
.dashFooter tr td:nth-child(2){
  width: 20%;
}

body {
  background-color: #FEFDF9; 
  color: #919eab !important;
  height: 100vh;
  display: block
}
.legText{
  color: '#919eab' !important;
}
.vega-embed details{
  display:hidden !important;
}

.root{
  height:100vh;
  width: 100vw;
}